import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import { get } from 'lodash';

import Layout from '../components/layout';
import WhyBoltAccess from '../components/why-bolt-access';
import ContactPromo from '../components/contact-promo';
import Form from '../components/forms/contact-form';

import '../styles/pages/carrier-contact-us.scss';

const CarrierContactUs = ({ data }) => {
  const pageData = get(
    data,
    'allDatoCmsFormTemplate.edges[1].node.formTemplate[0]'
  );

  useEffect(() => {
    const script = document.createElement('script');
    script.innerHTML = `
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        'event': 'carrierContactUsForm'
      });
    `;
    document.head.appendChild(script);
    return () => {
      document.head.removeChild(script);
    };
  }, []);

  return (
    <Layout title="Carrier - Contact Us">
      <section className="carrier-contact-hero-banner">
        <div className="wrapper">
          <div className="hero-content">
            <h2>Get in Touch</h2>
            <p>
              Our team is ready to help you get started writing more business.
              Give us a call or fill out the form below to talk to a
              representative.
            </p>
          </div>
        </div>
      </section>
      <section className="contact-form-section">
        <div className="wrapper">
          <div className="flex-container">
            <Form
              portalId="7013510"
              formId="07148129-2a06-48ec-8ce2-ce870997bdbb"
              targetId="carriert-bg-form"
            />
            <WhyBoltAccess />
          </div>
        </div>
      </section>
      <ContactPromo data={pageData} />
    </Layout>
  );
};

CarrierContactUs.propTypes = {
  data: PropTypes.objectOf(PropTypes.object).isRequired,
};

export default CarrierContactUs;

export const carrierContactUs = graphql`
  query carrierContactQuery {
    allDatoCmsFormTemplate {
      edges {
        node {
          formTemplate {
            ... on DatoCmsContact {
              id
              contactList {
                contactPromoList {
                  titleAndDescription
                  callUsTitle
                  contactUsNumber
                  contactEmailField
                  contactEmail
                }
              }
            }
          }
        }
      }
    }
  }
`;
